const durationLimits = [
	{
		'id': '1_5',
		'value': '1 - 5 days',
		'lowerLimit': '1',
		'upperLimit': '5'
	}, 
	{
		'id': '6_8',
		'value': '6 - 8 days',
		'lowerLimit': '6',
		'upperLimit': '8'
	}, 
	{
		'id': '9_15',
		'value': '9 - 15 days',
		'lowerLimit': '9',
		'upperLimit': '15'
	}, 
	{
		'id': '16_999',
		'value': '16+ days',
		'lowerLimit': '16',
		'upperLimit': '999'
	}
];

function sortByLabelAlpha(a, b) {
	return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0;
}

function sortBySortValue(a, b) {
	return a.sortValue - b.sortValue;
}

function durationToQueryParam(duration) {
	const map = {
		'1_5': '0',
		'6_8': '1',
		'9_15': '2',
		'16_999': '3'
	};
	return map[duration];
}

// viewport width < 768px
function isMobileViewport() {
	const windowBreakPoint = window.windowBreakPoint;
	if (typeof windowBreakPoint === 'function') {
		const width = windowBreakPoint();
		return width === 'xs' || width === 'sm';
	}
	return false;
}

const satelliteTrack = () => {
	/* eslint-disable */
	if (typeof digitalData !== 'undefined' && digitalData) {
		// update digital data obj
		digitalData.search = {
			...digitalData.search,
			bookingType: 'PCDIR',
			type: 'cruise-widget'
		}
	}
	/* eslint-enable */
}

export {
	durationLimits,
	sortByLabelAlpha,
	sortBySortValue,
	durationToQueryParam,
	isMobileViewport,
	satelliteTrack
};
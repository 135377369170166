export default class SearchWidgetState {
	selectedTrade = '';
	selectedDate = '';
	selectedPort = '';
	selectedDuration = '';
	willFetchData = true;
	didFetchData = false;
	allowShopping = true;
	isPastGuest = false;
	hasRecentlyViewed = false;
}
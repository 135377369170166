import axios from 'axios';
import { format as formatDate, parse as parseDate } from 'date-fns';
import { durationLimits } from './Utils';
import LocalStore from './LocalStore';

export default class DataService {

	constructor(config, store) {
		this.config = config;
		this.store = store;
		// ttl of 1 hour for local storage cache of data responses
		this.localStore = new LocalStore(config, 'pcl-ube-search-widget', 60 * 60);
	}

	async fetchToken() {
		const cachedToken = this.getCachedData('token');
		const headers = this.headers();
		if (cachedToken) {
			this.config.token = cachedToken;
			return;
		}
		const response = await axios.get(`${this.config.apiHost}/resdb/p1.0/dbtoken`, { headers, withCredentials: true });
		if (response && response.data) {
			const token = response.data.db_token;
			// 20 mins ttl for token
			this.setStoreItemAndCache('token', token, 20 * 60);
			this.config.token = token;
		}
		return null;
	}
	
	async fetchAdmin() {
		const cachedBookingEngine = this.getCachedData('admin');
		if (cachedBookingEngine) {
			this.setAdminProps(cachedBookingEngine);
			return;
		}		
		const headers = this.headers();
		const url = `${this.config.bookHost}/ube/p1.0/ube`;
		const response = await axios.get(url, { headers, withCredentials: true });
		if (response && response.data) {
			const bookingEngine = response.data;
			this.setStoreItemAndCache('admin', bookingEngine);
			this.setAdminProps(bookingEngine);
		}
		return;
	}

	setAdminProps(bookingEngine) {
		const { config } = this;
		const agencyId = bookingEngine.ube.bookingLocation.agencyId;
		if (agencyId) {
			config.agencyId = agencyId;
		}
		config.currency = bookingEngine.ube.bookingLocation.primaryCurrency;
		config.bookingCompany = bookingEngine.ube.bookingLocation.bookingCompany;
		config.allowShopping = bookingEngine.ube.settings.offerShopping;
		config.country = bookingEngine.ube.guest.contact.address.country;
	}

	async fetchTrades() {
		const cachedTrades = this.getCachedData('trades');
		if (cachedTrades) {
			this.setStoreItem('trades', cachedTrades);
			return;
		}
		const headers = this.headers();
		const url = `${this.config.apiHost}/resdb/p1.0/trades`;
		const params = { active: 'Y*' };
		const response = await axios.get(url, { headers, params, withCredentials: true });
		if (response && response.data) {
			this.setStoreItemAndCache('trades', response.data.trades);
		}
		return null;
	}

	async fetchPorts() {
		const cachedPorts = this.getCachedData('ports');
		const cachedPortIds = this.getCachedData('portIds');
		if (cachedPorts && cachedPortIds) {
			this.setStoreItem('ports', cachedPorts);
			this.setStoreItem('portIds', cachedPortIds);
			return;
		}
		const headers = this.headers();
		const response = await axios.get(`${this.config.apiHost}/resdb/p1.0/ports`, { headers, withCredentials: true });
		if (response && response.data) {
			const ports = response.data.ports;
			const portIds = this.portIdsByPorts(ports);
			this.setStoreItemAndCache('ports', {
				continents: response.data.continents,
				ports: ports
			});
			this.setStoreItemAndCache('portIds', portIds);
		}
		return null;
	}

	portIdsByPorts(ports) {
		return ports.map((port) => {
			// replace VAP ports with SAI
			return port.id === 'VAP' ? port.id.replace('VAP', 'SAI') : port.id;
		});
	}

	async fetchProducts() {
		const cachedProducts = this.getCachedData('products');
		if (cachedProducts) {
			this.store.products = cachedProducts;
			return;
		}
		const headers = this.headers();
		const params = {
			agencyCountry: this.config.country || 'US',
			cruiseType: 'C',
			voyageStatus: 'A',
			webDisplay: 'Y',
			promoFilter: 'all',
			light: true
		};
		const response = await axios.get(`${this.config.apiHost}/resdb/p1.0/products`, { headers, params, withCredentials: true });
		if (response && response.data) {
			this.setStoreItem('rawProducts', response.data.products);
			this.setLocalStoreItem('cruises', response.data.products);
		}
		return null;
	}

	getCachedData(key) {
		return this.localStore.getItem(key) && this.localStore.getItem(key).value;
	}

	headers() {
		const AppId = {
			agencyId: this.config.agencyId,
			cruiseLineCode: 'PCL',
			sessionId: '123456789',
			systemId: 'PB'
		};
		return {
			AppId,
			ProductCompany: this.config.productCompany,
			BookingCompany: this.config.bookingCompany || 'PC',
			ReqSrc: 'W',
			AuthToken: this.config.token,
			'pcl-client-id': this.config.pclClientId
		}
	}

	serializePorts(ports) {
		return ports.filter((port) => {
			return port.isEmbark || port.isDisembark;
		});
	}

	serializeProducts() {
		const products = this.store.rawProducts;
		const serialized = products.reduce((serialized, product) => {
			const { id, cruiseDuration, embkDbkPortIds, trades } = product;
			// trade
			const trade = Array.isArray(trades) ? trades[0] && trades[0].id : null;
			// dates
			const ships = Array.isArray(product.ships) ? product.ships : [];
			const dates = ships.reduce((dates, ship) => {
				const formatted = ship.sailDates.reduce((dates, date) => {
					if (date) {
						dates.push(this.formatDateString(date));
					}
					return dates;
				}, []);
				return [...dates, ...formatted];
			}, []).uniqify();
			// ports
			// Instead of passing both the embkDbkPortIds we only first(0th index) port since it is always the starting port 
			const ports = Array.isArray(embkDbkPortIds) ? this.validatePorts([embkDbkPortIds[0]]).uniqify() : [];
			// duration
			const durations = [];
			const duration = cruiseDuration ? this.findDurationLimit(cruiseDuration) : null;
			duration && durations.push(duration.id);

			serialized.push({
				id,
				trade,
				dates,
				ports,
				durations
			});
			return serialized;
		}, []);
		this.setStoreItemAndCache('products', serialized);
	}

	setStoreItemAndCache(key, item, ttl) {
		this.setLocalStoreItem(key, item, ttl);
		this.setStoreItem(key, item);
	}

	setStoreItem(key, item) {
		item = key === 'ports' ?  item.ports : item;
		this.store[key] = item;
	}

	setLocalStoreItem(key, item, ttl) {
		this.localStore.setItem(key, item, ttl);
	}

	formatDateString(dateString) {
		const date = parseDate(dateString, 'yyyyMMdd', new Date());
		return formatDate(date, 'MMyy');
	}

	validatePorts(ports) {
		const validPorts = this.store.portIds;
		return ports.filter((port) => {
			return validPorts.includes(port);
		});
	}

	findDurationLimit(durationLength) {
		return durationLimits.find((durationLimit) => {
			const { lowerLimit, upperLimit } = durationLimit;
			return durationLength >= lowerLimit && durationLength <= upperLimit;
		});
	}
}
import { 
	getUnixTime,
	fromUnixTime,
	differenceInSeconds
} from 'date-fns';

export default class LocalStore {
	constructor(config, namespace = 'local-storage', ttl = null) {
		this.config = config;
		this.namespace = namespace;
		this.ttl = ttl;
		this.storeWithoutLocation = ['token'];
		try {
			const storage = window.localStorage;
			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			this.storage = storage;
		}
		catch(e) {
			console.log('local storage unavailable');
			this.storage = {
				getItem: () => { return null; },
				setItem: () => {}
			}
		}
	}

	getItem(key) {
		const isActive = this._isActive(key);
		if (isActive) {
			const itemKey = this._formatKey(key);
			return this._getItem(itemKey);
		}
		return null;
	}

	setItem(key, item, ttl) {
		try {
			const timestamp = this._getNowTimestamp();
			const setKey = this._formatKey(key);
			ttl = ttl || this.ttl;
			const jsonString = JSON.stringify({
				expiry: timestamp + ttl,
				timestamp: timestamp,
				ttl: ttl,
				value: item
			});
			this.storage.setItem(setKey, jsonString);
		} catch(e) {
			console.log('error storing item', e);
		}
	}

	_isActive(key) {
		const formatKey = this._formatKey(key);
		const expiry = this._getItem(formatKey);
		if (!expiry) {
			return false;
		}
		const { timestamp, ttl } = expiry;
		const diff = this._timeDifferenceInSeconds(timestamp);
		const hasTTL = ttl !== null;
		const isWithinTTL = diff <= ttl;
		return hasTTL ? isWithinTTL : true;
	}

	_formatKey(key) {
		const { country, defaultCountry } = this.config;
		const storeWithoutLocation = this.storeWithoutLocation.includes(key);
		const location = storeWithoutLocation ? 'X' : country || defaultCountry;
		return `pcl-ube-search-${location}-${key}`;
	}

	_getItem(key) {
		return JSON.parse(this.storage.getItem(key));
	}

	_timeDifferenceInSeconds(timestamp) {
		const expiry = this._dateFromTimestamp(timestamp);
		const now = this._dateFromTimestamp(this._getNowTimestamp());
		return differenceInSeconds(now, expiry);
	}

	_dateFromTimestamp(timestamp) {
		return fromUnixTime(timestamp);
	}

	_getNowTimestamp() {
		const now = new Date();
		return getUnixTime(now);
	}

	_hasTtl() {
		return this.ttl !== null;
	}
}
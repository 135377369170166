import 'react-app-polyfill/ie11';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/find';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Helper logic to append combined css dynamically in widget app for font related testing
// var fileref = document.createElement("link");
// fileref.rel = "stylesheet";
// fileref.type = "text/css";
// fileref.href = window.location.hostname === 'localhost' ? 'https://stagewww.princess.com/css/global/princess.combined.css': `https://${window.location.hostname}/css/global/princess.combined.css`
// document.getElementsByTagName("head")[0].appendChild(fileref)


ReactDOM.render(<App />, document.getElementById('search-widget'));


import React, { Component } from 'react';
import SearchWidgetState from './SearchWidgetState';
import './ArrayHelpers.js';
import Config from './Config';
import DataService from './DataService';
import SearchFilters from './SearchFilters';
import SelectBox from './SelectBox';
import { isMobileViewport, satelliteTrack } from './Utils';
import cookies from 'js-cookie';
import './docroot-copy.css';
import './App.css';

class SearchWidget extends Component {
	constructor(props) {
		super(props);
		const config = new Config();
		const store = {};
		this.config = config;
		this.store = store;
		this.dataService = new DataService(config, store);
		this.searchFilters = new SearchFilters(config, store);
		this.state = new SearchWidgetState();
		this.state.isMobile = isMobileViewport();
	}

	async componentDidMount() {
		await this.fetchSettings();
		// dtm tracking
		satelliteTrack();
		this.setRedirectionURL();

		window.addEventListener('resize', this.resizeListener.bind(this));
		
		// Add the event listener when needed.
		window.addEventListener('keydown', this.OnEscapePressed.bind(this));

		window.addEventListener('load', ()=>{
			// clearing all filters at load (i.e. when user is loading the page or coming back search widget(home page) so that we don't see stale prefilled form data) 
			this.clearAllFilters(true);
		});		
	}


	OnEscapePressed(event) { 
		if(event.key === 'Escape'){
			// Destroy the event after it's no longer needed.
			document.removeEventListener('keydown', this.OnEscapePressed);
			const searchContainer = document.querySelector('.search-container');
			searchContainer && searchContainer.classList.remove('mobile-search-widget-expanded');
			this.setState({...this.state, selectionModified: true});
			return true;
		}
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.resizeListener);
	}

	resizeListener(){
		this.setState({...this.state, isMobile: isMobileViewport()})
	}

	toggleMobileExpanding(){
		const searchContainer = document.querySelector('.search-container');
		if(searchContainer){
			if(searchContainer.classList.value.includes('mobile-search-widget-expanded')){
				searchContainer.classList.remove('mobile-search-widget-expanded');
				const dummyInput = document.querySelector(".dummy-input label button");
				dummyInput && dummyInput.focus();
			} else {
				searchContainer.classList.add('mobile-search-widget-expanded');
				const destinationSelectBox = document.querySelector("#destination-select");
				destinationSelectBox && destinationSelectBox.focus();
			}
		}
	}

	async fetchSettings() {
		let { state, store, dataService } = this;
		try {
			await dataService.fetchToken();
			await dataService.fetchAdmin();
			if (!this.config.allowShopping) {
				this.setState({...state, allowShopping: false});
				return;
			}

			await Promise.all([dataService.fetchTrades(), dataService.fetchPorts(), dataService.fetchProducts()]);
			// if we are not using cached products, serialize the raw response
			if (!store.products) {
				dataService.serializeProducts();
			}
			let newState = Object.assign({}, state);
			newState.didFetchData = true;
			this.setState(newState);
		} catch (e) {
			this.setStateForNoData();
			// eslint-disable-next-line no-undef
			typeof _satellite === 'object' && _satellite.track && _satellite.track('errorTracking');
		}
	}

	setStateForNoData() {
		let newState = {...this.state};
		newState.willFetchData = false;
		this.setState(newState);
	}

	updateState(updatedPropertyName, updatedValue) {
		let state = {
			...this.state
		};
		state[updatedPropertyName] = updatedValue;
		this.setState(state);
	}

	setRedirectionURL() {
		const {country, ube} = this.config;
		const isRedirectionFromReact = this.dataService.localStore.storage[`pcl-ube-search-${country}-rvc-${ube}`];
		let newState = {...this.state};
		newState.redirectionUrl = '/cruise-search/recently-viewed/?recentlyViewed=true';
        let cookie = cookies.get('rvc');
		newState.hasRecentlyViewed = !!cookie || isRedirectionFromReact;
		this.setState(newState);
	}

	hasRecentlyViewed(isRedirectionFromReact) {
		let cookie = cookies.get('rvc');
		return !!cookie || isRedirectionFromReact;
	}

	handleChange(event, propertyName) {
		const { value, options } = event.target;
		this.updateState(propertyName, value);
		/* eslint-disable */
		if (window.digitalData && window.digitalData.search) {
			switch (propertyName) {
				case 'selectedTrade':
					digitalData.search.destination = options[options.selectedIndex].label;
					digitalData.search.destinationCode = value;
					break;
				case 'selectedDate':
					digitalData.search.date = value;
					break;
				case 'selectedPort':
					digitalData.search.port = options[options.selectedIndex].label;
					digitalData.search.portCode = value;
					break;
			}
		}
		/* eslint-enable */
		// This flag is stored to show/hide port tooltip - we only show port tooltip to first time user
		window.sessionStorage.setItem('oldSession', 'true')
		
		// This selectionModified state is stored to remove destination(trade) tooltip when selected any other filter
		this.setState({...this.state, selectionModified: true});
	}

	handlePastGuestChange() {
		this.setState({
			...this.state,
			isPastGuest: !this.state.isPastGuest
		});
	}

	renderSelectBoxes(fields) {
		const label = {
			selectedTrade: 'Cruising to',
			selectedPort: 'Start/End Port',
			selectedDate: 'Leaving'
		}

		// Fetches the current authentication state
		const isLoggedIn = !!window.guestAuth.status();
		
		// This needs to be found out????
		let nearestPort = null;

		const {country, ube} = this.config;
		const recentlyViewedProducts = this.dataService.localStore.storage[`pcl-ube-search-${country}-rvc-${ube}`] || null;
		// User is first time on home when the oldSession flag is undefined as well as there are no recently viewed products
		const isFirstTimeUser = !window.sessionStorage.getItem('oldSession') && !recentlyViewedProducts;

		// Reversing recentlyViewedProducts as the last one are the latest
		const tradesBrowsed = [];
		(JSON.parse(recentlyViewedProducts) || [])
		.reverse()
		.forEach(({productId, voyageIds}) => { 
			// We loop through each recentviewed object and try to match itinerary(product) in products data
			const matchingProduct = this.store.products.find(({id})=> (productId === id));
			// Once we find the product
			if(matchingProduct){
				const tradeCountNode = tradesBrowsed.find(({tradeId}) => tradeId === matchingProduct.trade);
				// we check in our tradesBrowsed (list of browsed trades) if the trade is already present in the list
				// then we simple increment the count of the voyages watched by adding length of voyages seen in current iteration of recently viewed node
				if(tradeCountNode){
					tradeCountNode.count += voyageIds.length;
				} else {
					tradesBrowsed.push({
						tradeId: matchingProduct.trade, 
						count: voyageIds.length, 
						label: (fields.find((field)=>field.name === 'Destination').options.find(({value})=>value === matchingProduct.trade)||{}).label
					});
				}
		}});

		let mostBrowsedTradeLabel = '';
		// There should be atleast one trade(cruises) explored and dropdown is untouched
		if(tradesBrowsed.length && !this.state.selectionModified) {
			// We pick the trade whose cruises has been seen more than 2 times and the one which is recent
			const mostBrowsedTrade = tradesBrowsed.find(({ count })=> count >= 2) || {};
			mostBrowsedTradeLabel = mostBrowsedTrade.label;
			const {tradeId } = mostBrowsedTrade;
			if(tradeId && this.state.selectedTrade !== tradeId){
				this.setState({...this.state, selectedTrade: tradeId});
			}
		}
							
		return fields.map((field) => {
			const selectedOption = field.options.find((option) => {
				return option.isSelected;
			});

			let tooltipText = '';
			if(!isLoggedIn){
				if(field.name === 'Destination' && mostBrowsedTradeLabel){
					tooltipText = `See more ${mostBrowsedTradeLabel} cruises to choose from?`
				} else if(field.name === 'Port' && isFirstTimeUser && !mostBrowsedTradeLabel && nearestPort){
					tooltipText = `Interested in cruises from ${nearestPort}?`
				}
			}
			return (
				<SelectBox
					key={field.name}
					fieldName={field.name}
					label={label[field.selectedPropertyKey]}
					selectedPropertyKey={field.selectedPropertyKey}
					options={field.options}
					handleChange={(event, selectedPropertyKey) => { this.handleChange(event, selectedPropertyKey); }}
					value={selectedOption.value} 
					tooltipText={tooltipText}
				/>
			);
		});
	}

	renderBigButtonHTML(isHiddenForDesktop, hasRecentlyViewed, redirectionUrl) {
		return (
			<div className={`${ isHiddenForDesktop ? 'hidden-md-up' : ''} align-center`}>
				<div className='bigButton'>
					<a href={`${this.config.wwwHost}/cruise-search/cruises`} className="button primary-btn font-size-p5" data-test-id="view-results-mobile">Find a Cruise Vacation</a>
				</div>
				{/* {this.renderMobileRecentlyViewed(hasRecentlyViewed, redirectionUrl)} */}
			</div>
		);
	}

	renderMobileRecentlyViewed(hasRecentlyViewed, redirectionUrl) {
		if (hasRecentlyViewed) {
			return (
				<div className="col-xs-pad-15-top">
					<a href={redirectionUrl}>See Recently Viewed</a>
				</div>
			);
		}
	}

	renderRecentlyViewed(hasRecentlyViewed, redirectionUrl) {
		if (hasRecentlyViewed) {
			return (
				<div className="recently-viewed-wrapper">
					<a className="recently-viewed-anchor" href={redirectionUrl}>Recently Viewed</a>
				</div>
			);
		}
	}

	clearAllFilters(comingViaOnLoad = false){
		const closeIcon = document.querySelector(".close-btn-mobile-search-widget");
		closeIcon && closeIcon.focus();
		this.setState({
			...this.state, 
			selectedTrade :'',
			selectedDate : '',
			selectedPort : '',
			selectionModified: !comingViaOnLoad
		})
	}

	onTabPressClearAll(event){
		if(event.key === 'Tab') {
			event.preventDefault();
			const closeIcon = document.querySelector(".close-btn-mobile-search-widget");
			closeIcon && closeIcon.focus();			
		}
	}

	render() {
		const { state, searchFilters } = this;
		const { allowShopping, willFetchData, didFetchData, hasRecentlyViewed, redirectionUrl } = state;
		let hideBigButtonOnDesktop = true;
		
		if (allowShopping) {
			if (willFetchData) {
				if (didFetchData) {
					searchFilters.filter(state);
					const fields = searchFilters.buildFields(state);
					const url = searchFilters.buildUrl(state);
					let dummyInputValue =  '';
					fields.forEach(({name, options = []})=>{
					
						let { label = '' } = options.find(({value, isSelected}) => {
							return value && isSelected;
						}) || {};

						if(label){
							if(name === 'Date'){
								label = label.split(' ').map((l, index) => index === 0 ? l.slice(0, 3): l).join(' ');
							}
							if(dummyInputValue === ''){
								dummyInputValue += label
							} else {
								dummyInputValue += `, ${label}`
							}
						}
							
					})
		

					return (
						<div className="search-container">
								<div  className={`dummy-input`}>
									<label htmlFor="dummy-input" className="search-label font-size-p4">
										<span className='col-xs-pad-15-left'>
											Cruising to <span className="visuallyhidden">edit</span>
										</span>
										<button 
											aria-label={dummyInputValue  ? `Cruising to  ${dummyInputValue} selected edit` : 'Cruising to Destination, departure port, date edit' }
											id="dummy-input"
											type='buton'  
											onClick={this.toggleMobileExpanding.bind(this)} 
											>
											{dummyInputValue || 'Destination, departure port, date'}
										</button>
									</label>
								</div>
							<div className="ube-search-widget" aria-labelledby="widget-header" role="group">
								<div className="widget-fields-wrapper">
									<h2 id="widget-header" className="visuallyhidden">Find a cruise</h2>
									<button title="Close (Esc)" type="button" className="close-btn-mobile-search-widget plain-text-btn" onClick={ this.toggleMobileExpanding.bind(this) } data-track="search-source" data-track-id="close-cruise-widget-mobile" data-test-id="close-search-widget-mobile">
											<svg aria-hidden="true"><use href="#icon-close" /></svg>
									</button>
								
									<div className="widget-fields" aria-label='Find a cruise'>
										{ this.renderSelectBoxes(fields) }
										<div id="search-widget-submit">
											<a href={url} className="button secondary-btn font-size-p5 search-cruises-btn" data-track="search-source" data-track-id="cruise-widget" data-test-id="view-results">SEARCH CRUISES</a>
										</div>
										<div className='align-center clear-all-btn-container'>
											<button className="plain-text-btn clear-all-btn font-size-p4" type="button" data-track="search-source" data-track-id="clear-widget" onClick={this.clearAllFilters.bind(this)} onKeyDown={this.onTabPressClearAll.bind(this)}  data-test-id="clear-filter-mobile">Clear all</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}
			} else {
				hideBigButtonOnDesktop = false;
				return (
					<div className='search-container top-spacing-20 bottom-spacing-20'>
						<div className="ube-search-widget col-xs-12 col-xs-pad-20-top col-xs-pad-20-bottom col-md-pad-0-top col-md-pad-0-bottom col-xs-pad-20 col-md-pad-40">
							{this.renderBigButtonHTML(hideBigButtonOnDesktop, hasRecentlyViewed, redirectionUrl)}
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className='search-container top-spacing-20 bottom-spacing-20'>
					<div className="ube-search-widget no-shopping hidden"></div>
				</div>
			);
		}
	}
}

export default SearchWidget;
